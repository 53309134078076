export function header_sp() {

  //ハンバーガー
  const hamburger = document.querySelector('.js-hamburger');
  const body = document.querySelector('body');
  const main = document.querySelector('.l-wrap_over');
  const header = document.querySelector('.l-header');
  const headerSp = document.querySelector('.l-headerSp');
  const headerSp_overlay = document.querySelector('.l-headerSp_overlay');

  hamburger.addEventListener('click', () => {
    hamburger.classList.toggle('is-active');
    body.classList.toggle('is-noscroll');
    main.classList.toggle('is-active');
    header.classList.toggle('is-active');
    headerSp.classList.toggle('is-view');
    headerSp_overlay.classList.toggle('is-view');
  });

  let slide_trigger = document.querySelectorAll('.js-slide');
  const headerSp_main = document.querySelector('.l-headerSp_inner');

  for (let index = 0; index < slide_trigger.length; index++) {
    const trigger_element = slide_trigger[index];
    const slide_element = trigger_element.nextElementSibling;

    trigger_element.addEventListener('click', () => {
      headerSp_main.classList.add('is-hidden');
      slide_element.classList.add('is-view');
    });
  }

  let slide_close = document.querySelectorAll('.js-slideClose');

  for (let index = 0; index < slide_close.length; index++) {
    const close_element = slide_close[index];
    const close_target = close_element.closest(".l-headerSp_sub"); 
    
    close_element.addEventListener('click', () => {
      headerSp_main.classList.remove('is-hidden');
      close_target.classList.remove('is-view');
    });
  }

  headerSp_overlay.addEventListener('click', () => {
    hamburger.classList.remove('is-active');
    body.classList.remove('is-noscroll');
    main.classList.remove('is-active');
    header.classList.remove('is-active');
    headerSp.classList.remove('is-view');
    headerSp_overlay.classList.remove('is-view');
  });
}