export function mouseover() {
  const trigger = document.querySelectorAll('.js-mouseover');

  for (let index = 0; index < trigger.length; index++) {
    const trigger_element = trigger[index];
    const panel_element = trigger_element.querySelector('.js-mouseover--panel');

    //マウスが要素上に入った時
    trigger_element.addEventListener('mouseover', () => {
      panel_element.classList.add('is-view');
    }, false);

    //マウスが要素上から離れた時
    trigger_element.addEventListener('mouseleave', () => {
      panel_element.classList.remove('is-view');
    }, false);
  }
}