import ScrollMagic from 'scrollmagic';

export function scrollmagic() {

  //Enter処理
  let controllerEnter = new ScrollMagic.Controller();

  let scrollEnter = document.querySelectorAll('.c-scroll_enter');

  for (let i = 0; i < scrollEnter.length; i++) {
    let tag = scrollEnter[i];

    new ScrollMagic.Scene({
      triggerElement: tag,
      triggerHook: 'onEnter',
      offset: 0,
    })
      .setClassToggle(tag, 'is-animated')
      .addTo(controllerEnter);
  }

  //pagetop処理
  let controller_pagetop = new ScrollMagic.Controller();

  let scroll_pagetop = document.querySelectorAll('.js-pagetop');

  for (let i = 0; i < scroll_pagetop.length; i++) {
    let tag = scroll_pagetop[i];

    if (window.matchMedia('(max-width: 767px)').matches) {
        //スマホ処理
        new ScrollMagic.Scene({
          triggerElement: tag,
          triggerHook: 'onEnter',
          offset: 85,
        })
        .setClassToggle(tag, 'is-animated')
        .addTo(controller_pagetop);
    } else if (window.matchMedia('(min-width:768px)').matches) {
        //PC処理
        new ScrollMagic.Scene({
          triggerElement: tag,
          triggerHook: 'onEnter',
          offset: 120,
        })
        .setClassToggle(tag, 'is-animated')
        .addTo(controller_pagetop);
    }
  }

  //Center処理
  let controllerCenter = new ScrollMagic.Controller();

  let scrollCenter = document.querySelectorAll('.js-scrollAnimation02');

  for (let i = 0; i < scrollCenter.length; i++) {
    let tag = scrollCenter[i];

    new ScrollMagic.Scene({
      triggerElement: tag,
      triggerHook: 'onCenter',
      offset: 0,
    })
      .setClassToggle(tag, 'is-animated')
      .addTo(controllerCenter);
  }

  //Leave処理
  let controllerLeave = new ScrollMagic.Controller();

  let scrollLeave = document.querySelectorAll('.js-scrollAnimation03');

  for (let i = 0; i < scrollLeave.length; i++) {
    let tag = scrollLeave[i];

    new ScrollMagic.Scene({
      triggerElement: tag,
      triggerHook: 'onLeave',
      offset: 0,
    })
      .setClassToggle(tag, 'is-animated')
      .addTo(controllerLeave);
  }
}
