import { throttle } from 'lodash';

export function scrollAction() {

  window.addEventListener(
    'scroll',
    _.throttle(
      e => {
        // ここに処理
        var y = window.pageYOffset ;

        //headerOut
        const header = document.querySelector('.l-header');
        const trigger_headerOut = document.querySelector('.js-headerOut');
        if ( trigger_headerOut.classList.contains('is-animated') == true ) {
          header.classList.add('is-fadeout');
        } else {
          header.classList.remove('is-fadeout');
        }

        

        //追従バナー
        const pagetop = document.querySelector('.l-footer_pagetop');
        const footer = document.querySelector('.js-pagetop');
        if( footer.classList.contains('is-animated') == true ){
          //何か処理を書く
          pagetop.classList.add('is-absolute');
        } else {
          pagetop.classList.remove('is-absolute');
        }

        const top = document.querySelector('.js-pagetop_top');
        if ( top.classList.contains('is-animated') == true ) {
          pagetop.classList.add('is-view');
        } else {
          pagetop.classList.remove('is-view');
        }

        //ハッピーキャリアアップスクール
        // パターン1
        if(document.getElementsByClassName('p-career_ico').length) {
          const contact_btn = document.querySelector('.p-career_ico');

          if( footer.classList.contains('is-animated') == true ){
            //何か処理を書く
            contact_btn.classList.add('is-absolute');
          } else {
            contact_btn.classList.remove('is-absolute');
          }

          if ( top.classList.contains('is-animated') == true ) {
            contact_btn.classList.add('is-view');
          } else {
            contact_btn.classList.remove('is-view');
          }
        }
        return false;
      },
      50,
      { trailing: true, leading: true }
    )
  );
}