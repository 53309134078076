export function facility() {

  // 要素をスライドしながら非表示にする関数(jQueryのslideUpと同じ)
  const slideUp = (el, duration = 300) => {
    el.style.height = el.offsetHeight + "px";
    el.offsetHeight;
    el.style.transitionProperty = "height, margin, padding";
    el.style.transitionDuration = duration + "ms";
    el.style.transitionTimingFunction = "ease";
    el.style.overflow = "hidden";
    el.style.height = 0;
    el.style.paddingTop = 0;
    el.style.paddingBottom = 0;
    el.style.marginTop = 0;
    el.style.marginBottom = 0;
    setTimeout(() => {
      el.style.display = "none";
      el.style.removeProperty("height");
      el.style.removeProperty("padding-top");
      el.style.removeProperty("padding-bottom");
      el.style.removeProperty("margin-top");
      el.style.removeProperty("margin-bottom");
      el.style.removeProperty("overflow");
      el.style.removeProperty("transition-duration");
      el.style.removeProperty("transition-property");
      el.style.removeProperty("transition-timing-function");
      el.classList.remove("is-open");
    }, duration);
  };

  // 要素をスライドしながら交互に表示/非表示にする関数(jQueryのslideToggleと同じ)
  const slideToggle = (el, duration = 300) => {
    return slideUp(el, duration);
  };

  function scrolltop() {
    //スムーズスクロール
    var scroll_element = document.getElementById('js-scroll-target'); // 移動させたい位置の要素を取得
    scroll_element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  const group = document.querySelector('.p-facilitySearch_list');
  const trigger = document.querySelectorAll('.c-btn_search--sub');

  for (let index = 0; index < trigger.length; index++) {
    const trigger_element = trigger[index];
    let parent_element = trigger_element.closest('.p-facilitySearch_list--wrap');
    let parent_element02 = trigger_element.closest('.p-facilitySearch_list--item');
    let parent_element03 = trigger_element.parentNode;
    let parent_element04 = trigger_element.closest('.p-facilitySearch_district');

    let accordion_btn = parent_element02.querySelector('.c-btn_search');

    let actived_alement = group.querySelector('.is-active');


    trigger_element.addEventListener('click', () => {

      if(document.getElementsByClassName('is-selected').length) {
        let selected_element = group.querySelector('.is-selected');
        selected_element.classList.remove('is-selected');
      }
      trigger_element.classList.add('is-selected');

      if ( trigger_element.classList.contains('disable') == false ) {
        slideToggle(parent_element);
        accordion_btn.classList.remove('is-active');
      }

      if (trigger_element.classList.contains('tokyo') == true) {
        const tokyo_group = document.getElementById('tokyo_group');
        const tokyo_btn = document.getElementById('tokyo_btn');
        tokyo_btn.classList.remove('is-active');
        tokyo_btn.classList.add('is-close');
        slideToggle(tokyo_group);
      } else if (trigger_element.classList.contains('osaka') == true) {
        const osaka_group = document.getElementById('osaka_group');
        const osaka_btn = document.getElementById('osaka_btn');
        osaka_btn.classList.remove('is-active');
        osaka_btn.classList.add('is-close');
        slideToggle(osaka_group);
      }

      //絞り込み検索

      //地方絞り込み
      //全エリア取得
      const area_elements = document.getElementsByClassName('p-facility_sec');
      //クリックしたボタンがどの地方に含まれるのかを取得
      const target_parent = parent_element02.querySelector('.c-btn_search');
      const target_parent_data = target_parent.dataset.areaBtn;
      console.log(target_parent_data);
      //クリックした地方の要素を取得
      const target_area = document.getElementById(target_parent_data);
      //クリックした地方の要素以外を非表示

      if ( trigger_element.classList.contains('disable') == false ) {
        Array.prototype.forEach.call(area_elements, function (area_element) {
          if ( area_element !== target_area ) {
            area_element.classList.add('displayNone');
          } else {
            area_element.classList.remove('displayNone');
          }
        });
      }


      //クリックしたボタンのdata値を取得
      const target_data = trigger_element.dataset.areaBtn;
      console.log(target_data);

      //全県グループを取得
      const targets = document.getElementsByClassName('p-facility_area');
      //クリックした県以外を非表示
      function change() {
        Array.prototype.forEach.call(targets, function (target) {
          if ( target !== target_element ) {
            target.classList.add('displayNone');
          } else {
            target.classList.remove('displayNone');
          }
        });
      }
      //クリックした区以外非表示
      function change02() {
        var facility_elements = target_element.querySelectorAll('.c-boxFacility');
        Array.prototype.forEach.call(facility_elements, function (facility_element) {
          var facility_element_data = facility_element.dataset.area;
          if ( facility_element_data !== target_data ) {
            facility_element.classList.add('displayNone');
          } else {
            facility_element.classList.remove('displayNone');
          }
        });
      }

      //県か区を区別する
      if ( trigger_element.classList.contains('tokyo') == true ) {
        scrolltop();
        var target_element = document.getElementById('tokyo');
        //クリックした県以外を非表示
        change();
        //クリックした区以外非表示
        change02();
      } else if ( trigger_element.classList.contains('osaka') == true ) {
        scrolltop();
        var target_element = document.getElementById('osaka');
        //クリックした県以外を非表示
        change();
        //クリックした区以外非表示
        change02();
      } else if ( trigger_element.classList.contains('disable') == false ) {
        scrolltop();
        var target_element = document.getElementById(target_data);
        //クリックした県以外を非表示
        change();
      }
    });
  }
}