export function header_fadein() {
  const target = document.querySelector('.l-header');
  var startPos = 0;
  var winScrollTop = 0;

  window.addEventListener('scroll', () => {
    winScrollTop = window.pageYOffset;
    if ( target.classList.contains('is-fadeout') == true ) {
      const scroll_length = startPos - winScrollTop;

      if ( scroll_length < -30 ) {
        target.classList.remove('is-fadein');
      } else if ( scroll_length > 30 ) {
        target.classList.add('is-fadein');
      }
    }

    startPos = winScrollTop;
  });
}