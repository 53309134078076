export function modal() {
  var trigger = document.querySelectorAll('.js-modal');
  var body = document.body;
  var overlay = document.querySelector('.c-modal_overlay');
  var close_btn = document.querySelectorAll('.c-modal_close');

  for ( let i = 0; i < trigger.length; i++ ) {
    let trigger_element = trigger[i];
    let target = trigger_element.dataset.target;
    let target_element = document.getElementById(target);

    function open() {
      body.classList.add('is-noscroll');
      overlay.classList.add('is-view');
      trigger_element.classList.add('is-active');
      target_element.classList.add('is-view');
    }

    function close() {
      body.classList.remove('is-noscroll');
      overlay.classList.remove('is-view');
      trigger_element.classList.remove('is-active');
      target_element.classList.remove('is-view');
      if (target_element.querySelector('video') !== null){
        // 存在する場合の処理
        let video_element = target_element.querySelector('video');
        video_element.pause();
      }
    }

    trigger_element.addEventListener('click', () => {
      open();
    });

    overlay.addEventListener('click', () => {
      close();
    });

    for ( let i = 0; i < close_btn.length; i++ ) {
      let close_btn_element = close_btn[i];
      close_btn_element.addEventListener('click', () => {
        close();
      });
    }
  }
}