import { modal } from "./modules/modal";
import { scrollmagic } from "./modules/scrollmagic";
import { height } from "./modules/height";
import { scrollAction } from "./modules/scrollAction";
import { smooth } from "./modules/smooth";
import { header_fadein } from "./modules/header_fadein";
// import { kv_slide02 } from "./modules/kv_slide02";
import { slide_img } from "./modules/slide_img";
import { accordion } from "./modules/accordion";
import { facility } from "./modules/facility";
import { header_pc } from "./modules/header_pc";
import { header_sp } from "./modules/header_sp";
import { mouseover } from "./modules/mouseover";
import { kv_slide03 } from "./modules/kv_slide03";
import { facility_accordion } from "./modules/facility_accordion";
import { sdgs_accordion } from "./modules/sdgs_accordion";

window.addEventListener('load', () => {
  //crollに応じてアニメーションしたい時
  　scrollmagic();
  
    //crollによる処理を間引きしたい時
  　scrollAction();
});

modal();
height();
smooth();
header_fadein();
slide_img();
accordion();
facility();
header_pc();
header_sp();
mouseover();
kv_slide03();
facility_accordion();
sdgs_accordion();