export function sdgs_accordion() {
  function viewHeight() {
    const accordion_panel02 = document.querySelectorAll('.js-sdgs_accordion--panel');
    for (let index = 0; index < accordion_panel02.length; index++) {
      const accordion_panel02_element = accordion_panel02[index];
      const accordion_panel02_height = accordion_panel02_element.querySelector('.js-sdgs_accordion--panel--inner').clientHeight;
      if ( accordion_panel02_element.classList.contains('is-view') == true ) {
        accordion_panel02_element.style.height = accordion_panel02_height + 'px';
      }
    }
  }

  // viewHeight();

  window.addEventListener('resize', function(){
    viewHeight();
  });


  const triggers = document.querySelectorAll('.js-sdgs_accordion--trigger');
  for (let index = 0; index < triggers.length; index++) {
    const trigger = triggers[index];
    trigger.addEventListener('click', ()=> {

      viewHeight();

      if ( trigger.classList.contains('is-active') == true ) {
        trigger.classList.remove('is-active');
        trigger.innerHTML = "詳しくみる";
      } else {
        trigger.classList.add('is-active');
        trigger.innerHTML = "閉じる";
      }

      const accordion = trigger.closest('.js-sdgs_accordion');
      const accordion_panel = accordion.querySelector('.js-sdgs_accordion--panel');
      const accordion_panel_inner = accordion.querySelector('.js-sdgs_accordion--panel--inner');
      const accordion_panel_height = accordion_panel_inner.clientHeight;

      accordion_panel.classList.remove('is-view02');
      if ( accordion_panel.classList.contains('is-view') == true ) {
        accordion_panel.classList.remove('is-view');
        accordion_panel.classList.add('is-close');
        accordion_panel.style.height = '65px';
        if (window.matchMedia('(max-width: 767px)').matches) {
        //スマホ処理
          accordion_panel.style.height = '5rem';
        } else if (window.matchMedia('(min-width:768px)').matches) {
            //PC処理
            accordion_panel.style.height = '6.5rem';
        }
      } else {
        accordion_panel.classList.add('is-view');
        accordion_panel.classList.remove('is-close');
        accordion_panel.style.height = accordion_panel_height + 'px';
      }
    });
  }
}