export function kv_slide03() {
  //スライダー全体
  const kv_slider = document.getElementById('js-kvSlider');
  //スライド全部の中身
  const slides = document.querySelectorAll('.js-kvSlider--item');
  //1枚目のスライド
  const slide01 = document.getElementById('slide01');
  //ナビゲーション関係
  const dots_group = document.getElementById('js-kvSlider-navigation');
  //dotを全て取得
  const dots = document.querySelectorAll('.js-kvSlide-dot');
  //1個目のドット
  const dot01 = document.getElementById('dot01');
  //ドットボタン
  const dot_btns = document.querySelectorAll('.js-kvSlide-dotBtn');

  var timer1 = null;

  //ナビゲーション
  function navigation() {
    const current_dot = dots_group.querySelector('.is-current');
    const current_dot_id = current_dot.id;
    const next_dot = current_dot.nextElementSibling;

    function navigation_chenge() {
      current_dot.classList.remove('is-current');
      next_dot.classList.add('is-current');
    }

    if ( current_dot_id === 'dot05' ) {
      current_dot.classList.remove('is-current');
      dot01.classList.add('is-current');
    } else {
      navigation_chenge();
    }
  }

  //アニメーション（スライドが小さくなるやつ）
  function animation() {
    // slides.forEach(function( slide ) {
    //   slide.classList.add('is-animation');
    //   setTimeout(function() {
    //     slide.classList.remove('is-animation');
    //   }, 2000);
    // });

    Array.prototype.forEach.call(slides, function(slide) {
      slide.classList.add('is-animation');
      setTimeout(function() {
        slide.classList.remove('is-animation');
      }, 2000);
    });
  }

  //.is-scroll付け替え
  function isSlider() {
    kv_slider.classList.add('is-slider');
    setTimeout(function() {
      kv_slider.classList.remove('is-slider');
    }, 2000);
  }

  //全体スライド
  function slider() {
    //現在表示されているスライドとそのidを取得
    const current = kv_slider.querySelector('.is-current');
    const current_id = current.id;
    // console.log(current_id);

    //現在表示されているスライドの次のスライドを取得
    const next = current.nextElementSibling;

    //現在表示されているスライドから次のスライドに.is-currentを付け替える
    function current_change() {
      current.classList.remove('is-current');
      next.classList.add('is-current');
    }

    //6枚目から1枚目に移動する（リピート再生）
    function current_change_repeat() {
      current.classList.remove('is-current');
      slide01.classList.add('is-current');
      kv_slider.classList.add('slide06');
      setTimeout(function() {
        kv_slider.classList.remove('slide06');
        kv_slider.classList.add('slide01');
      }, 1000);
    }

    //#js-kvSliderのクラスを付け替える
    function next_slide() {
      setTimeout(function() {
        kv_slider.classList.remove(current_id);
        if ( current_id === 'slide01' ) {
          current_change();
          kv_slider.classList.add('slide02');
        } else if ( current_id === 'slide02' ) {
          current_change();
          kv_slider.classList.add('slide03');
        } else if ( current_id === 'slide03' ) {
          current_change();
          kv_slider.classList.add('slide04');
        } else if ( current_id === 'slide04' ) {
          current_change();
          kv_slider.classList.add('slide05');
        } else if ( current_id === 'slide05' ) {
          current_change_repeat();
        }
      }, 1000);
    }


    //実行
    animation();
    isSlider();
    navigation();
    next_slide();
  }

  //オート再生を停止する処理
  function stopTimer(){
    clearInterval(timer1);
  }


  //実行
  //#js-kvSliderが存在する時に実行される
  if( kv_slider != null ) {
    // console.log("kv_slide03!");
    setTimeout(function() {
      if ( kv_slider.classList.contains('is-active') == false ) {
        slider();
        timer1 = setInterval(slider, 6000);
      }
    }, 3000);


    //ナビゲーションがクリックされたときに実行
    for (let index = 0; index < dot_btns.length; index++) {
      const dot_btn = dot_btns[index];
      dot_btn.addEventListener('click', () => {

        kv_slider.classList.add('is-active');

        if ( kv_slider.classList.contains('is-slider') == false ) {
          stopTimer();
          const current_dot = dots_group.querySelector('.is-current');
          const dot_btn_parent = dot_btn.parentNode;
          const dot_btn_target = dot_btn.dataset.target;
          const current_slide = kv_slider.querySelector('.is-current');
          const next_slide = document.getElementById(dot_btn_target);

          current_dot.classList.remove('is-current');
          dot_btn_parent.classList.add('is-current');
          // console.log(dot_btn_target);

          function target_slide() {
            current_slide.classList.remove('is-current');
            next_slide.classList.add('is-current');
            setTimeout(function() {
              kv_slider.classList.remove('slide01');
              kv_slider.classList.remove('slide02');
              kv_slider.classList.remove('slide03');
              kv_slider.classList.remove('slide04');
              kv_slider.classList.remove('slide05');
              kv_slider.classList.add(dot_btn_target);
            }, 1000);
          }
  
          animation();
          isSlider();
          target_slide();
          timer1 = setInterval(slider, 6000);
        }
      });
    }
  }
}