export function header_pc() {
  const trigger = document.querySelectorAll('.js-dropdown');
  const header_element = document.querySelector('.l-header_inner');

  for (let index = 0; index < trigger.length; index++) {
    const trigger_element = trigger[index];
    
    trigger_element.addEventListener('mouseover', () => {
      var target_element = trigger_element.querySelector('.l-headerNavi_global--box');
      var target_h = target_element.clientHeight;
      var target_element_wrap = trigger_element.querySelector('.l-headerNavi_global--wrap');
    
      target_element_wrap.style.height = (target_h + 115) + 'px';
      target_element_wrap.classList.add('is-view');
      header_element.classList.add('is-active');
    }, false);

    trigger_element.addEventListener('mouseleave', () => {
      var target_element_wrap = trigger_element.querySelector('.l-headerNavi_global--wrap');
      target_element_wrap.style.height = '0px';
      target_element_wrap.classList.remove('is-view');
      header_element.classList.remove('is-active');
    }, false);
  }
}